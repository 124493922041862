<template>
  <div class="hidden-danger-govern flex-column">
    <div class="nameTit flex-space-between">
      <div class="tp-left flex-center-v">
        <span style="margin-right: 10px;">隐患类别统计</span>
        <el-date-picker
            size="small"
            v-model="timer"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd"
            style="width: 400px;margin-right: 10px;">
        </el-date-picker>
        <el-button type="primary" size="small" style="width:80px;" @click="search">查询</el-button>
      </div>
      <div class="tp-right flex-center-v">
      </div>
    </div>
    <div class="cont flex-1-auto flex" style="padding-top: 20px;">
      <div class="flex-center" style="width: 40%; height: 100%;">
        <div id="pieSta" style="width: 500px;height: 500px;"></div>
      </div>
      <div style="width: 1px; height: 100%;margin: 0 10px;border-right: 1px dashed #cdcdcd;"></div>
      <div style="width: 60%; height: 100%;">
        <el-table ref="multipleTable" :data="tableList" height="100%" style="width: 100%" :header-cell-style="{background:'#f2f2f2',color:'#333333'}">
          <el-table-column type="index" label="序号" align="center"></el-table-column>
          <el-table-column prop="name" label="类别名称" align="center" show-overflow-tooltip></el-table-column>
          <el-table-column prop="value" label="隐患个数" align="center" show-overflow-tooltip></el-table-column>
          <el-table-column prop="rate" label="占比" align="center"></el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
import apis from "./apis3";
import * as echarts from 'echarts';

export default {
  name: "hidden-danger-govern",

  components: {},

  props: {},

  data() {
    return {
      tableList: [],
      timer: '',
      searchData: {
        checkStartDate: '',
        checkEndDate: ''
      },
      pieList: [],
      allNum: 0,
    }
  },

  computed: {},

  watch: {
  },

  mounted() {
    this.getTableList().async
  },

  methods: {
    async getTableList() {
      const result = await apis.hiddenDangerCategoryData(this.searchData)
      const pieList = []
      let allNum = 0
      result.map(v => {
        pieList.push({
          name: v.name,
          value: v.value
        })
        allNum += v.value
        v.rate = v.rate + '%'
        return v
      })
      this.allNum = allNum
      this.pieList = pieList
      this.tableList = result
      this.initEcharts()
    },
    initEcharts() {
      let chartDom = document.getElementById('pieSta');
      let myChart = echarts.init(chartDom);
      let option = {
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b}: {c} ({d}%)'
        },
        title: {
          text: '隐患总数' + this.allNum,
          left: 'center',
          top: '48%',
          textStyle: {
            fontSize: 20
          }
        },
        series: [
          {
            name: '访问来源',
            type: 'pie',
            radius: ['45%', '65%'],
            avoidLabelOverlap: false,
            data: this.pieList
          }
        ]
      }
      option && myChart.setOption(option)
    },
    // 查询
    search() {
      if (this.timer) {
        this.searchData.checkStartDate = this.timer[0]
        this.searchData.checkEndDate = this.timer[1]
      } else {
        this.searchData.checkStartDate = ''
        this.searchData.checkEndDate = ''
      }
      this.getTableList().async
    },
  }
}
</script>

<style lang="stylus" scoped>
.hidden-danger-govern {
  height 100%
  padding 10px
}
</style>
